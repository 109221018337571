// Toggle sidebar
const sidebarToggle = document.getElementById('sidebar-toggle-switch');

const toggleSidebar = () => {
  const sidebarContainer = document.getElementById('sidebar-container');
  if (!sidebarContainer) {
    return;
  }

  const toggleText =
    sidebarContainer.style.display === 'none' ? 'Hide' : 'Show';

  const contentContainer = document.getElementById('content-container');
  if (sidebarContainer.classList.contains('d-none')) {
    sidebarContainer.classList.toggle('d-block');
    sidebarContainer.classList.toggle('d-none');
  } else {
    sidebarContainer.classList.toggle('d-block');
    sidebarContainer.classList.toggle('d-none');
  }

  const sidebarChevron = document.getElementById('sidebar-chevron');
  sidebarChevron?.classList.toggle('bi-chevron-right');
  sidebarChevron?.classList.toggle('bi-chevron-down');
  contentContainer?.classList.toggle('col-md-9');

  const toggleHeaders = document.querySelectorAll('.toggle-header');
  for (const header of toggleHeaders) {
    header.classList.toggle('offset-md-3');
  }

  const sidebarAction = document.getElementById('sidebar-action');
  if (sidebarAction) {
    sidebarAction.textContent = toggleText;
  }
};

if (sidebarToggle) {
  sidebarToggle.addEventListener('click', toggleSidebar);

  // Toggle it to initially off.
  toggleSidebar();
}

// Prevent dropdown menu form clicks from propagating
for (const form of document.querySelectorAll('.dropdown-menu form')) {
  form.addEventListener('click', (e) => {
    e.stopPropagation();
  });
}

// Clear empty errors. XXX - this seems to only be used in
// website/templates/account/includes/pay-as-you-go.html.ep and probalby shouldn't
// be in the main JS.
const clearEmptyError = function (target: HTMLElement) {
  const handleInput = () => {
    if (
      target instanceof HTMLInputElement ||
      target instanceof HTMLTextAreaElement
    ) {
      if (target.value && target.value.trim() === '') {
        target.classList.add('is-empty');
      } else {
        target.classList.remove('is-empty');
      }
    }
  };

  target.addEventListener('input', handleInput);
  target.addEventListener('change', handleInput);
  target.addEventListener('focusin', handleInput);
};

const requiredSelectors = [
  'input[type="text"][required]',
  'input[type="number"][required]',
  'input[type="email"][required]',
  'textarea[required]',
];

const requiredTextInputs = document.querySelectorAll(
  requiredSelectors.join(','),
) as NodeListOf<HTMLElement>;

for (const input of requiredTextInputs) {
  clearEmptyError(input);
}
