import { subnav } from './subnav';

const hasTransparentNav = document.querySelector(
  '.js-has-transparent-nav',
) as HTMLElement;
const scrollTrigger = 60;

const checkScrollHeight = () => {
  if (window.scrollY >= scrollTrigger) {
    hasTransparentNav.classList.remove('has-transparent-nav');
  } else {
    hasTransparentNav.classList.add('has-transparent-nav');
  }
};

if (hasTransparentNav) {
  checkScrollHeight();

  document.addEventListener('scroll', () => {
    checkScrollHeight();
  });

  if (subnav) {
    hasTransparentNav.classList.add('has-subnav');
  } else {
    hasTransparentNav.classList.remove('has-subnav');
  }
}

const navBtns: NodeListOf<HTMLButtonElement> = document.querySelectorAll(
  '.js-main-nav__link, .js-utility-nav__link',
);

for (const btn of navBtns) {
  btn.disabled = false;
}

const navItems = document.querySelectorAll('.js-main-nav__item');

const showItem = (event: Event) => {
  const navItem = event.currentTarget as HTMLElement;
  const navMenuItem = navItem.querySelector('.main-nav__dropdown-menu');
  navItem.setAttribute('aria-expanded', 'true');
  navItem.classList.add('show');
  navMenuItem?.classList.add('show');
};

const hideItem = (event: Event) => {
  const navItem = event.currentTarget as HTMLElement;
  const navMenuItem = navItem.querySelector('.main-nav__dropdown-menu');
  navItem.setAttribute('aria-expanded', 'false');
  navItem.classList.remove('show');
  navMenuItem?.classList.remove('show');
};

const hoverNavItems = () => {
  const isDesktopScreenSize = window.innerWidth > 767;
  const isHoverableDevice = window.matchMedia('(hover: hover)').matches;

  navItems.forEach((navItem) => {
    // first remove any previously added event listeners. there
    // is no way to check if an event listener is already
    // added to an element, so we do this to prevent adding
    // duplicate event listeners
    navItem.removeEventListener('mouseover', showItem);
    navItem.removeEventListener('mouseout', hideItem);
    if (!isHoverableDevice || !isDesktopScreenSize) return;
    navItem.addEventListener('mouseover', showItem);
    navItem.addEventListener('mouseout', hideItem);
  });
};

window.addEventListener('resize', hoverNavItems);
window.addEventListener('load', hoverNavItems);
